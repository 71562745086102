.likert-container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items:center;
    position: relative;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
   

    .likert-item{
        z-index: 1;
        cursor:pointer;
        margin-top: 5px;
        width: 17%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        .likert-smiley{
            width:40px;
            height:40px;
            border-radius: 20px;
            background-color: white;
            border:2px solid #ced4da;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
            margin-bottom: 15px;
            >svg{
                width: 75%;
                height: 75%;
                opacity: 0.5;
            }
            >.point{
                opacity: 0.5;
                width: 50%;
                height: 50%;
                border-radius: 50%;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
            }
        }
        &.selected{
            .likert-smiley{
                >svg{
                    opacity: 1;
                    width: 90%;
                    height: 90%;
                }
                >.point{
                    opacity: 1;
                    width: 75%;
                    height: 75%;
                }
            }
        }
        &.hovered{
            svg{
                opacity: 0.75;
                width: 90%;
                height: 90%;
            }
            .point{
                opacity: 0.75;
                width: 75%;
                height: 75%;
            }
        }
    }
    &:not(.no-before)::before{
        content:" ";
        height: 2px;
        position: absolute;
        top:37%;
        z-index: 0;
        background-color:#ced4da;
    }
    &.item-5{
        &::before{
            width: 75%;
            left:calc(25%/2);
        }    
        .likert-item:nth-child(1){
            .likert-smiley{
                >svg{
                    color:rgb(251,20,51) !important;
                }
                >.point{
                    background-color: rgb(251,20,51);
                }
            }
        }
        .likert-item:nth-child(2){
            .likert-smiley{
                >svg{
                    color:rgb(251, 105, 20) !important;
                }
                >.point{
                    background-color: rgb(251, 105, 20);
                }
            }
        }
        .likert-item:nth-child(3){
            .likert-smiley{
                >svg{
                    color:rgb(251, 189, 20) !important;
                }
                >.point{
                    background-color: rgb(251, 189, 20);
                }
            }
        }
        .likert-item:nth-child(4){
            .likert-smiley{
                >svg{
                    color:rgb(183, 228, 20) !important;
                }
                >.point{
                    background-color: rgb(183, 228, 20);
                }
            }
        }
        .likert-item:nth-child(5){
            .likert-smiley{
                >svg{
                    color:rgb(59, 251, 20) !important;
                }
                >.point{
                    background-color: rgb(59, 251, 20);
                }
            }
        }
    }
    
    &.item-3{
        &::before{
            width: 60%;
            left:calc(40%/2);
        }    
        .likert-item:nth-child(1){
            .likert-smiley{
                >svg{
                    color:rgb(251,20,51) !important;
                }
                >.point{
                    background-color: rgb(251,20,51);
                }
            }
        }
        .likert-item:nth-child(2){
            .likert-smiley{
                >svg{
                    color:rgb(251, 189, 20) !important;
                }
                >.point{
                    background-color: rgb(251, 189, 20);
                }
            }
        }
        .likert-item:nth-child(3){
            .likert-smiley{
                >svg{
                    color:rgb(59, 251, 20) !important;
                }
                >.point{
                    background-color: rgb(59, 251, 20);
                }
            }
        }
    }

    // ------------------------------------------------ label container ------------------------------------------------
    &.no-before{
        align-items:flex-start;
        .likert-item{
            .likert-label{
                width: 100%;
                font-size: 1rem;
                font-family: "Roboto","Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                font-weight: 300;
                text-align: center;
                color:rgba(0,0,0,0.85);
            }
            &.selected{
                .likert-label{
                    font-weight: 600;
                    color:rgba(0,0,0,1);
                }
            }
            &.hovered{
                .likert-label{
                    font-weight: 600;
                    color:rgba(0,0,0,0.92);
                }
            }
        }
    }
}