html{
  padding: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  body{
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #root{
      padding: 0px;
      margin: 0px;
      width: 100%;
      height: 100%;
    }
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




