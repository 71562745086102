.form-check {
    margin-left: 15px;
    position:unset;
    display: block;
    padding-left: 0px;
    >label{
        font-size: 1rem;
        font-family: "Roboto","Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-weight: 300;
        text-align: center;
        color:rgba(0,0,0,0.85);
        &.checked{
            color:rgba(0,0,0,1);
        }
        >.form-check-input {
            position:unset;
            margin: 0px;
        }
    }
}