#prompt-srl{
    height: 100%;
    width: 100%;
    .subformulaire{
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 60px calc(100% - 160px) 50px 50px;
        grid-template-columns: 100%;
        margin-left: 0px;
        margin-right: 0px;
        .cell{
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-child(1){
                grid-row: 1/2;
                grid-column: 1/2;
                >.progression{
                    margin-top: 15px;
                    margin-bottom: 15px;
                    height: 30px;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: row;
                    
                    >div{
                        height:5px;
                        border-radius: 2px;
                    }
                }
            }
            &:nth-child(2){
                grid-column: 1/2;
                grid-row: 2/3;
                width: 100%;
                height: 100%;
                >#elements{
                    display: flex;
                    flex-direction: row;
                    justify-content:flex-start;
                    align-items: center;
                    width: 100%;
                    max-height: 100%;
                    overflow-x: hidden;
                    margin-bottom: 0px;
                    .item{
                        background-color: rgba(247, 247, 247, 0.8);
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        min-width: 95%;
                        max-width: 95%;
                        height: 100%;
                        margin-left: 2.5%;
                        margin-right: 2.5%;
                        border:1px solid #ced4da;
                        border-radius: .25rem;
                        padding: 10px 10px;
                        >.description{
                            margin-bottom: 25px;
                        }
                        &.error{
                            border:1px solid red;
                        }
                        >label{
                            font-size: 1.15rem;
                            margin-bottom: 15px;
                        }
                        >img{
                            align-self: center;
                            max-width: 70%;
                            height:calc(100% - 250px);
                            padding: 5px;
                            margin-bottom: 15px;
                            border-radius: .25rem;
                            border: 1px solid rgba(0,0,0,0.8);
                            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.75);
                        }
                    }
                    .margin-item-right{
                        min-width: 1px;
                        max-width: 1px;
                        height: 1px;
                    }
                    
                    span{
                        vertical-align: middle;
                        width: 150px;
                        line-height: 50px;
                    }
                    label{
                        font-weight: 500;
                    }
                    
                    
                    div.objectives{
                        border-radius: .25rem;
                        margin-bottom: 5px;
                        p.title{
                            font-weight: 400;
                            font-style: italic;
                            margin-bottom: 5px;
                        }
                        p.items{
                            font-weight: 200;
                            font-style: italic;
                            margin-bottom: 5px;
                            color:rgba(0,0,0,0.8);
                        }
                    }
                }
            }
            &:nth-child(3){
                grid-column: 1/2;
                grid-row: 3/4;
                >#validate_prompt_srl{
                    min-width:150px;
                    background-color: #29458C;
                    border-color:#29458C;
                    &:focus{
                        box-shadow: 0 0 0 0.2rem rgba(53, 88, 179, 0.5);
                    }
                }
            }
            &:nth-child(4){
                justify-self: flex-end;
                grid-column: 1/2;
                grid-row: 4/5;
                >#cancel_prompt_srl{
                    width:250px;
                    margin-right: 2.5%;
                    background-color: white;
                    color:rgb(255, 53, 70);
                    border:1px solid transparent;
                    &:focus, &:active, &:focus{
                        box-shadow:none;
                    }
                }
            }
        }
    }
}

